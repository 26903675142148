<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="bar">
        <div class="title">确认购药信息</div>   
        <div class="rb1" @click="goback">
          <img src="@/assets/gb.png" class="icon" />
          <div class="text">关闭</div>
        </div>   
      </div>
      <div class="content">
        <div class="form">
          <div class="item">
            <div class="listbox">
              <img src="@/assets/zt_by.png" class="ztimg" />                                        
            </div>       
          </div>

          <div class="item">
            <div class="listbox">
              <div class="name">
                <span class="m">{{ name }}</span>
                <span class="m">{{ mobile }}</span>   
                <span class="m">{{ certificate_code }}</span>                              
              </div>
              <!--<div class="name">
                <span class="m">{{ certificate_code }}</span>                          
              </div>
              <div class="name">
                <span class="m">{{ address }}</span>                            
              </div>-->
            </div>     
          </div>

          <div class="item">
            <div class="listbox">
              <div class="name">
                <span class="m">就诊人：</span>
                <span class="m">{{ name }}</span>
                <span class="m">{{ sex }}</span>
                <span >{{ age }}岁</span>                             
              </div>
              <div class="name">
                <span class="m">诊</span>   
                <span class="m">断：</span>  
                <span class="m">{{ diagnosis }}</span>                       
              </div>
              <div class="name">
                <span class="m">医</span>   
                <span class="m">生：</span> 
                <span class="m">{{ doctor }}</span>   
                <span class="r" @click="prescription">查看处方笺</span>                       
              </div>
            </div>     
          </div>

          <div class="item">
            <div class="listbox">
              <div class="name">
                <span class="m">{{ ypname1 }}</span>
                <span class="m l">{{ metering1 }}</span>     
                <span class="r z">¥{{ price1 }}</span>                        
              </div>
              <div class="name dd">
                <span class="m">{{ factory1 }}</span>                          
              </div>             
              <div class="name">
                <span class="m">购药数量</span>    
                <span class="s">{{ number1 }}</span>                     
              </div>
              <div class="name">
                <span class="r g">¥{{ pricexj1 }}</span> 
                <span class="m">小计</span> 
              </div> 
              <div class="name">
                <span class="r red">-&nbsp;¥{{ pricebx1 }}</span> 
                <span class="m">医保报销</span> 
              </div>
            </div>     
          </div>
          
          <div class="item">
            <div class="listbox">
              <div class="name">
                <span class="m">{{ ypname2 }}</span>
                <span class="m l">{{ metering2 }}</span>   
                <span class="r z">¥{{ price2 }}</span>                          
              </div>
              <div class="name dd">
                <span class="m">{{ factory2 }}</span>                          
              </div>             
              <div class="name">
                <span class="m">购药数量</span>    
                <span class="s">{{ number2 }}</span>                      
              </div>
              <div class="name">
                <span class="r g">¥{{ pricexj2 }}</span> 
                <span class="m">小计</span> 
              </div> 
              <div class="name">
                <span class="r red">-&nbsp;¥{{ pricebx2 }}</span> 
                <span class="m">医保报销</span> 
              </div>
            </div>     
          </div> 
          <div class="item">
            <div class="listbox">
              <div class="name">
                <span class="m">{{ ypname3 }}</span>
                <span class="m l">{{ metering3 }}</span>   
                <span class="r z">¥{{ price3 }}</span>                          
              </div>
              <div class="name dd">
                <span class="m">{{ factory3 }}</span>                          
              </div>             
              <div class="name">
                <span class="m">购药数量</span>    
                <span class="s">{{ number3 }}</span>                     
              </div>
              <div class="name">
                <span class="r g">¥{{ pricexj3 }}</span> 
                <span class="m">小计</span> 
              </div> 
              <div class="name">
                <span class="r red">-&nbsp;¥{{ pricebx3 }}</span> 
                <span class="m">医保报销</span> 
              </div>
            </div>     
          </div>           
          <div class="item">
            <div class="listbox">
              <div class="name">
                <span class="m">费用明细</span>                         
              </div>
              <div class="name">
                <span class="r g">¥{{ pricezj }}</span> 
                <span class="m">总计</span> 
              </div> 
              <div class="name">
                <span class="r red">-&nbsp;¥{{ pricebx }}</span> 
                <span class="m">医保报销</span> 
              </div>
            </div>     
          </div>   
          <div class="item">
            <div class="listbox"> 
              <div class="name">
                <span class="r yf">¥{{ pricezj-pricebx }}</span> 
                <span class="m">应付金额</span> 
              </div>
            </div>     
          </div>     
        </div>       
        <div class="button primary mm" @click="submit">确认订单</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  created() {

    this.token_id = this.$route.query.token_id;
    this.openid = this.$route.query.openid;
    this.user_openid = this.$route.query.user_openid;
    this.certificate_code = this.$route.query.certificate_code;
    this.mobile = this.$route.query.mobile;
    this.name = this.$route.query.name;
    this.SiteCode = this.$route.query.SiteCode;
    this.ClientID = this.$route.query.ClientID;   
    this.age = this.$route.query.age; 
    this.sex = this.$route.query.sex; 

    this.ypname1 = this.$route.query.ypname1;
    this.factory1 = this.$route.query.factory1;
    this.metering1 = this.$route.query.metering1;
    this.price1 = this.$route.query.price1;
    this.number1 = this.$route.query.number1;
    this.ypname2 = this.$route.query.ypname2;
    this.factory2 = this.$route.query.factory2;
    this.metering2 = this.$route.query.metering2;
    this.price2 = this.$route.query.price2;
    this.number2 = this.$route.query.number2;
    this.ypname3 = this.$route.query.ypname3;
    this.factory3 = this.$route.query.factory3;
    this.metering3 = this.$route.query.metering3;
    this.price3 = this.$route.query.price3;
    this.number3 = this.$route.query.number3;

    this.pricexj1 = (this.price1*this.number1).toFixed(2);
    this.pricexj2 = (this.price2*this.number2).toFixed(2);
    this.pricexj3 = (this.price3*this.number3).toFixed(2);
    this.pricebx1 = (this.pricexj1*0.7).toFixed(2);
    this.pricebx2 = (this.pricexj2*0.7).toFixed(2);
    this.pricebx3 = (this.pricexj3*0.7).toFixed(2);
    //this.pricezj = (this.pricexj1 + this.pricexj2 + this.pricexj3).toFixed(2);
    //this.pricebx = (this.pricebx1 + this.pricebx2 + this.pricebx3).toFixed(2);
    this.pricezj = ((this.price1*this.number1) + (this.price2*this.number2) + (this.price3*this.number3)).toFixed(2);
    this.pricebx = ((this.pricexj1*0.7) + (this.pricexj2*0.7) + (this.pricexj3*0.7)).toFixed(2);
    
  },
  data() {
    return {
      token_id: '',
      openid: '',
      user_openid: '',
      certificate_code: '',
      mobile: '',
      name: '',
      SiteCode: '',
      ClientID: '',
      age: '',
      sex: '',
      address: '吉林省长春市朝阳区湖西路1028号长影21栋',
      diagnosis: '高血压',
      doctor: '刘鑫', 
      ypname1: '',
      factory1: '',
      metering1: '',
      price1: 0,
      number1: 1,
      ypname2: '',
      factory2: '',
      metering2: '',
      price2: 0,
      number2: 1,
      ypname3: '',
      factory3: '',
      metering3: '',
      price3: 0,
      number3: 1,  
      pricexj1: 0, 
      pricexj2: 0, 
      pricexj3: 0, 
      pricebx1: 0, 
      pricebx2: 0, 
      pricebx3: 0,
      pricezj: 45, 
      pricebx: 31.5,
      pricehj: 13.5,   
      ddrflag11: true,
      ddrflag12: false,
      ddrflag21: true,
      ddrflag22: false,
      ddrflag23: false,
      ddrflag24: false,
      ddrflag31: true,
      ddrflag32: false,
      ddrflag33: false,
      ddrflag34: false,
      num1: 1,
      num2: 1,
      num3: 1,
    };
  },
  methods: {
    prescription() {
      Toast('暂未开放');
    },
    goback() {
      Dialog.confirm({
        message: "退出操作页面！"
      }).then(async () => {
        window.WeixinJSBridge.invoke('closeWindow',{},function(res){});
      });
    },    
    async submit() {
      /*if (isEmpty(this.name)) {
        Toast('吧台名称不可以为空!');
        return;
      }*/

      let { data } = await this.axios.post(
        '/medicines/yd_tlService/yd_hz_messages',
        qs.stringify({ 
          token_id: this.token_id, 
          openid: this.openid, 
          user_openid: this.user_openid, 
          certificate_code: this.certificate_code, 
          mobile: this.mobile, 
          name: this.name, 
          SiteCode: this.SiteCode, 
          ClientID: this.ClientID, 
          sex: this.sex, 
          age: this.age, 
          ypname1: this.ypname1,
          factory1: this.factory1,
          metering1: this.metering1,
          price1: this.price1,
          number1: this.number1,
          ypname2: this.ypname2,
          factory2: this.factory2,
          metering2: this.metering2,
          price2: this.price2,
          number2: this.number2,
          ypname3: this.ypname3,
          factory3: this.factory3,
          metering3: this.metering3,
          price3: this.price3,
          number3: this.number3 
        })
      );
      if (data.flag === '0') {
        Dialog.confirm({
          showCancelButton: false,
          message: data.message+"，退出操作页面！"
        }).then(async () => {
          window.WeixinJSBridge.invoke('closeWindow',{},function(res){});
        });
      } else {
        Toast(data.message);
        //Dialog({ message: data.message });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 366px;
}
.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 50px;
    color: #fff;
    font-size: 35px;
    float: left;
    margin-top: 10px;
  }
  
  
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    margin-right: 8px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  
}
.content {
  margin-top: 0px;
  padding: 10px 40px 50px 40px;
}
.listbox {
  flex: 1;
  overflow: hidden;
  .red {
    color: rgb(180, 4, 4);
  }  
  .ztimg {
    width: 100%;
    vertical-align: middle;
  } 
  .name {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    font-size: 28px;
    &.dd {
      font-size: 25px;
      color: rgb(136, 134, 134);
    }
    .icon {
      width: 28px;
      height: 28px;
      display: block;
      float: right;
      margin-top: 15px;
    }
    .dxicon {
      width: 30px;
      height: 30px;
      vertical-align: middle;
    } 
    .m {
      float: left;
      margin-right: 30px;
      &.kg {
        margin-right: 10px;
        margin-left: 30px;
      }
      &.red {
        color: rgb(236, 8, 38);
      }
      &.l {
        color: rgb(141, 202, 228);
        font-size: 25px;
      }
    }
    .d {
      float: left;
      margin-left: 70px;
      &.l {
        margin-left: 0px;
      }
    }
    .r {
      float: right;
      color: rgb(17, 61, 202);
      &.bq {
        width: 120px;
        margin-right: 30px;
      }
      &.red {
        color: rgb(236, 8, 38);
      }
      &.g {
        color: rgb(8, 182, 159);
      }
      &.z {
        color: rgb(127, 49, 218);
      }
      &.yf {
        color: rgb(1, 59, 248);
      }
    }
    .s {
      float: right;
      color: rgb(136, 134, 134);
      margin-right: 10px;
      margin-left: 10px;
    }
    .zdy {
      float: left;
    }
    .input {
      vertical-align: middle;
      width: 125px;
      border: 0;
      font-size: 25px;
      color: rgb(136, 134, 134);
      line-height: 60px;
      float: right;
      &.rr {
        margin-left: 72px;
        float: left;
        width: 399px;
      }
    }
    .txt {
      vertical-align: middle;
      font-size: 25px;
      float: right;
      margin-left: 10px;
      margin-right: 10px;
      color: rgb(136, 134, 134);
    }
    .txt1 {
      vertical-align: middle;
      font-size: 25px; 
      color: rgb(136, 134, 134);    
    }
    .arrow {
      float: right;
      display: block;
      //margin-left: 10px;
      margin-top: 8px;
      height: 40px;
    }
    .text {
      font-size: 25px;
      float: right;
      color: rgb(136, 134, 134);
    }
    .form_select1 {
      /* 清除默认边框 */
      border: 0;
      /* 清除默认的箭头样式 */
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      /* 右侧添加小箭头的背景图 */
      background: url('/img/arrow_1.png') 98% center no-repeat;
      background-size: 26px;
      width: 100%;
      height: 60px;
      background-color: transparent;
      font-size: 25px;
      text-align: right;
      color: rgb(136, 134, 134);
      line-height: 60px;
      vertical-align: middle;
      float: right;
      direction: rtl;
      padding-right: 40px;
    }
    select:focus { outline: none; }
  }
  .name1 {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    font-size: 35px;
    font-weight:800;
    margin-top: 52px;
    margin-left: 34px;
    .dxicon {
      width: 35px;
      height: 35px;
      vertical-align: middle;
    } 
    .txt {
      vertical-align: middle;
      font-size: 25px;
      float: right;
      margin-left: 10px;
      margin-right: 10px;
      color: rgb(136, 134, 134);
    }
  }
  .unames {
    height: 30px;
    line-height: 30px;
    font-size: 28px;
    color: #666666;
    margin-top: 30px;
    margin-left: 36px;
  }
  .unames1 {
    height: 30px;
    line-height: 30px;
    font-size: 28px;
    color: #666666;
    margin-top: 10px;
    margin-left: 36px;
  }
  
}
.listbox1 {
  flex: 1;
  overflow: hidden;
  width: 50px;
  .name {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    font-size: 28px;   
    .dxicon {
      width: 35px;
      height: 35px;
      vertical-align: middle;
      margin-right: 6px;
      &.ll {
        margin-left: 10px;
      }
    }
    .txt {
      vertical-align: middle;
      font-size: 25px; 
      color: rgb(136, 134, 134);    
    }
    .arrow {
      float: right;
      vertical-align: middle;
      height: 40px;
    }
    .text {
      font-size: 25px;
      float: right;
    }
    .form_select {
      /* 清除默认边框 */
      border: 0;
      /* 清除默认的箭头样式 */
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      /* 右侧添加小箭头的背景图 */
      background: url('/img/arrow_1.png') 98% center no-repeat;
      background-size: 26px;
      width: 100%;
      height: 60px;
      background-color: transparent;
      font-size: 25px;
      color: rgb(136, 134, 134);
      line-height: 60px;
      vertical-align: middle;
      direction: rtl;
      padding-right: 40px;
    } 
    select:focus { outline: none; }

    .form_select2 {
      /* 清除默认边框 */
      border: 0;
      /* 清除默认的箭头样式 */
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      /* 右侧添加小箭头的背景图 */
      background: url('/img/xl1.png') 56px center no-repeat;
      background-size: 26px;
      width: 80px;
      height: 60px;
      background-color: transparent;
      font-size: 25px;
      color: rgb(136, 134, 134);
      line-height: 60px;
      vertical-align: middle;
      margin-left: 15px;
    } 
    select:focus { outline: none; } 

  }  
  .name1 {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    font-size: 28px;
    .dxicon {
      width: 35px;
      height: 35px;
      vertical-align: middle;
      margin-right: 6px;
      &.ll {
        margin-left: 10px;
      }
    }
    .txt {
      vertical-align: middle;
      font-size: 25px; 
      color: rgb(136, 134, 134);    
    }
  }
}

.ts {
  height: 60px;
  width: 50px;
  line-height: 60px;
  overflow: hidden;
  font-size: 25px;
  display: block;
  float: right;
  color: rgb(136, 134, 134);
}
.listbox2 {
  overflow: hidden;
  .unames {
    height: 60px;
    line-height: 60px;
    font-size: 28px;
    color: #c90c0c;
    margin-top: 22px;
    margin-right: 34px;
    float: right;
  }
  .iconkg {
    width: 80px;
    display: block;
    float: right;
  }  
     
}
</style>